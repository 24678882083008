
import { Component, Vue, Watch } from 'vue-property-decorator';
import { queryBanner, updateStatus, updateSort } from '@/api/operation/banner';
import { changeLoading } from '@/util/decorators';
import EditableCell from '@/component/editable-cell.vue';
import { useGlobalFunction } from '@/pinia/modules/globalFunction';
import NModel from '@/component/NModel/NModel.vue';
import { useForm } from '@/util/useForm';
import moment from 'moment';

@Component({
  name: 'OperationBannerList',
  components: {
    EditableCell,
    NModel,
  },
})
export default class OperationBannerList extends Vue {
  setGlobalFunction = useGlobalFunction().setGlobalFunction; //更新数据
  form = useForm();
  is_loading: boolean = false;
  next_page: number = 1;
  preview_image: string = ''; // 示例图片预览路径
  preview_visible: boolean = false; // 控制示例图片显示和隐藏
  queryParams: any = {
    title: undefined,
    status: undefined,
    position: undefined,
  }; // 搜索条件
  banner_table_data: any = {};
  pagination: any = {};

  // paginationOption(data: RemotePagination<any>) {
  //   return {
  //     total: data.total || 0,
  //     showTotal: () => `共${data.total}条，每页${data.limit}条`,
  //     pageSize: data.limit || 1,
  //     current_page: data.current || 1,
  //   };
  // }

  handleTableChange(pagination: any, filters, sorter) {
    this.next_page = pagination.current;
    let sort: any = sorter.order; // 排序类型，正序/倒序/不排序
    this.fetchBannerData(this.next_page, undefined, sort);
    if (sorter.columnKey == 'start_time') {
      if (sort == 'ascend') {
        sort = 'asc';
      } else if (sort == 'descend') {
        sort = 'desc';
      } else {
        sort = undefined;
      }
      this.fetchBannerData(this.next_page, sort, undefined);
    } else {
      if (sort == 'ascend') {
        sort = 'asc';
      } else if (sort == 'descend') {
        sort = 'desc';
      } else {
        sort = undefined;
      }
      this.fetchBannerData(this.next_page, undefined, sort);
    }
  }

  // 显示模态窗，改变模态窗中的图片路径
  handlePreview(e: any) {
    this.preview_image = e.target.src;
    this.preview_visible = true;
  }
  // 关闭模态窗
  handlePreviewCancel() {
    this.preview_visible = false;
  }

  // 点击搜索按钮
  @changeLoading(['is_loading'])
  async query() {
    this.next_page = 1;
    const res = await queryBanner(this.next_page, {
      title: this.queryParams.title,
      status: this.queryParams.status,
      position: this.queryParams.position,
    });
    this.banner_table_data = res;
    // this.banner_table_data = await queryBanner(this.next_page, {
    //   title: this.queryParams.title,
    //   status: this.queryParams.status,
    //   position: this.queryParams.position,
    // });
    this.pagination = {
      total: res.total || 0,
      showTotal: () => `共${res.total}条，每页${res.limit}条`,
      pageSize: res.limit || 1,
      current: res.current || 1,
    };
    // this.paginationOption(this.pagination);
  }
  // 清空搜索条件
  clear() {
    this.queryParams = {};
    // this.paginationOption(this.pagination);
    // this.form.value.resetFields();
    this.fetchBannerData();
  }
  // 更改排序
  @changeLoading(['is_loading'])
  async handleSortChange(record: any, value: number) {
    /* if (!value) {
      this.$message.error('修改失败');
      return;
    } */
    if (!/^[0-9]+$/.test(value + '')) {
      this.$message.error('排序值须为>0的整数值！');
      return;
    }
    const res: any = await updateSort(record.id, value);
    if (res.status !== 200) {
      return this.$message.error(res.message);
    }
    this.$message.success('修改成功');
    this.fetchBannerData(this.next_page);
  }

  // 编辑上/下架
  @changeLoading(['is_loading'])
  async handleStatusChange(record: any) {
    let status = 1;
    switch (record.status) {
      case 1:
        status = 2;
        break;
      case 2:
        status = 1;
        break;
      default:
        break;
    }
    const res: any = await updateStatus(record.id, status);
    if (res.status !== 200) {
      this.$message.error(res.message);
      return;
    }
    this.fetchBannerData(this.next_page);
  }

  @changeLoading(['is_loading'])
  async fetchBannerData(next_page: number = 1, start?: string, end?: string) {
    const send_data: any = {
      start_time_sort: start,
      end_time_sort: end,
      title: this.queryParams.title,
      status: this.queryParams.status,
      position: this.queryParams.position,
    };
    // const res = await queryBanner(next_page);
    const res = await queryBanner(next_page, send_data);
    this.banner_table_data = res;
    this.pagination = {
      total: res.total || 0,
      showTotal: () => `共${res.total}条，每页${res.limit}条`,
      pageSize: res.limit || 1,
      current: res.current || 1,
    };
    // this.banner_table_data[start_time] = dateFormat(this.banner_table_data[start_time])
  }
  Format(time) {
    return moment(time * 1000).format('YYYY-MM-DD HH:mm:ss');
  }
  mounted() {
    this.fetchBannerData();
    this.setGlobalFunction({ fetchBannerData: this.fetchBannerData }); //更新数据
  }
}
