
import { Component, Vue, Watch } from 'vue-property-decorator';
import { changeLoading } from '@/util/decorators';
import { WARE_STATUS_LIST, queryWare } from '@/api/ware/management';
import { getSimpleCategory } from '@/api/ware/category';
import {
  addLimitTime,
  getWareList,
  LimitTimeWareData,
  initLimitTimeWareData,
  editLimitTime,
} from '@/api/operation/panic_buying';
import CustomModal from '@/component/custom-modal.vue';
import EditableCell from '@/component/editable-cell.vue';
import storage from 'baimahu/storage';
import { PaginationData } from '@/api/operation/customer';
import { useGlobalFunction } from '@/pinia/modules/globalFunction';
@Component({
  components: {
    CustomModal,
    EditableCell,
  },
})
export default class AddPanicSecond extends Vue {
  globalFunction= useGlobalFunction().globalFunction;  //更新数据
  form_item_layout: any = {
    labelCol: { span: 3 },
    wrapperCol: { span: 21 },
  };
  delete_product_id: number = 0;
  WARE_STATUS = WARE_STATUS_LIST;
  is_disabled: boolean = false;
  is_edit: boolean = false;
  is_view: boolean = false;
  is_loading: boolean = false;
  current_page: number = 1;
  first_step_data: any = {};
  checked_list: any = [];
  selected_id_list: any = []; // 表格选中项的 id
  ware_category_list: any = [];
  ware_table_id_list: any = []; // 表格已有项的 id
  ware_table_list: any = []; //
  table_data: any = { detail: [] };
  ware_modal: SimpleModal = {
    title: '添加限时商品',
    visible: false,
    is_loading: false,
    filter: {
      ware_id: '',
      ware_name: '',
      category_id: undefined,
      status: '0',
    },
    ware_index: 0,
    next_page: 1, // 分页数
    all_ware_data: {}, // 所有的数据
    table_list: [], // 表格数据
    selected_id_list: [], // 选中项的 id
    selected_data: [], // 选中项的数据
  };
  delete_modal: SimpleModal = {
    title: '',
    visible: false,
    ware_index: 0,
    is_more: false, // 是否批量删除
  };
  delete_product: number[] = [];
  get checked_all(): boolean {
    if (!this.table_data.detail.length) {
      return false;
    }
    return this.selected_id_list.length === this.table_data.detail.length;
  }

  get ware_modal_selection() {
    return {
      selectedRowKeys: this.ware_modal.selected_id_list,
      onChange: (selected_row_keys: any, selected_rows: any) => {
        const id_list = this.ware_modal.selected_data.map((item: any) => item.id);
        this.ware_modal.selected_id_list = selected_row_keys;
        this.ware_modal.selected_data = this.ware_modal.selected_data.concat(selected_rows);
        const obj: any = {};
        this.ware_modal.selected_data = this.ware_modal.selected_data.reduce(
          (item: any, next: any) => {
            if (!obj[next.id]) {
              item.push(next);
              obj[next.id] = true;
            }
            return item;
          },
          []
        );
        // this.ware_modal.table_list.detail.forEach((item: any) => {
        //   if (
        //     this.ware_modal.selected_id_list.indexOf(item.id) !== -1 &&
        //     id_list.indexOf(item.id) === -1
        //   ) {
        //     this.ware_modal.selected_data.push(item);
        //   }
        // });
      },
    };
  }

  get ware_table_row_selection() {
    return {
      selectedRowKeys: this.selected_id_list,
      onChange: (selected_row_keys: any) => {
        this.selected_id_list = selected_row_keys;
      },
      getCheckboxProps: () => ({
        props: {
          disabled: this.is_disabled,
        },
      }),
    };
  }

  // 给商品数据添加上父分类
  @Watch('table_data', { deep: true })
  addTableData(n: any) {
    this.ware_modal.selected_id_list = this.table_data.detail.map((item: any) => item.product_id);
    this.table_data.detail.forEach((ware: any, index: number) => {
      this.ware_category_list.map((ware_category: any) => {
        if (
          ware_category.id === ware.product.category.parent_id &&
          ware.product.category.parent_id !== 0
        ) {
          ware.product.category.parent_category = `${ware_category.name} /`;
        }
      });
      // ware.sort = index + 1;
    });
  }
  @Watch('ware_modal', { deep: true })
  getWareModalData() {
    this.addParentCategory(this.ware_modal.table_list.detail);
  }

  wareModalPaginationOption(data: any) {
    return {
      current: +data.current || 0,
      total: data.total || 0,
      showTotal: () => `共${data.total}条，每页${data.limit}条`,
      pageSize: data.limit || 0,
    };
  }

  hhh(){
    return false
  }
  addParentCategory(arr: any) {
    arr.forEach((ware: any) => {
      this.ware_category_list.map((ware_category: any) => {
        if (ware_category.id === ware.category.parent_id && ware.category.parent_id !== 0) {
          ware.category.parent_category = `${ware_category.name} /`;
        }
      });
    });
  }

  paginationOption(data: any) {
    return {
      total: data.detail.length,
      showTotal: () => `共${data.detail.length}条，每页10条`,
      pageSize: 10,
      current_page: 1,
    };
  }

  sortTableList(table: any) {
    table.sort((a: any, b: any) => a.limit_time_sort - b.limit_time_sort);
  }

  getAddSendData() {
    const product = this.table_data.detail.map((item: any) => {
      return {
        product_id: item.product_id,
        sort: item.sort,
        promotion_price: item.product.promotion[0].promotion_price,
      };
    });
    const send_data = {
      ...storage.getItem('send_data'),
      product: product,
    };
    return send_data;
  }

  // ===================================================
  // 删除商品
  onDeleteWare(record: any, index: number) {
    if (this.is_disabled) {
      return;
    }
    this.delete_product_id = record.product_id;
    this.delete_modal.ware_index = index;
    this.delete_modal.visible = true;
    this.delete_modal.is_more = false;
  }

  // 关闭添加限时商品模态窗
  async closeWareModal() {
    this.ware_modal.filter.category_id = undefined;
    this.ware_modal.filter.status = '0';
    this.ware_modal.filter.ware_id = '';
    this.ware_modal.filter.ware_name = '';
    // this.ware_modal.selected_id_list = [];
    await this.handleSearchWare();
    this.ware_modal.visible = false;
  }

  // 批量删除
  onDeleteMoreWare() {
    this.delete_modal.visible = true;
    this.delete_modal.is_more = true;
  }

  // 当页全选
  onSelectAll(e: any) {
    const checked = e.target.checked;
    if (checked) {
      this.selected_id_list = this.table_data.detail.map((item: any) => item.id);
    } else {
      this.selected_id_list = [];
    }
  }

  // 更改排序
  async onSortChange(record: any, value: number, index: number) {
    if (!value) {
      return;
    }
    // const id_list = this.table_data.detail.map((item: any) => item.product_id);
    record.sort = value;
    // this.table_data.detail[id_list.indexOf(record.product_id)].sort = value;
    // this.table_data.detail = this.table_data.detail.sort((a: any, b: any) => {
    //   return a.sort - b.sort;
    // });
    //record.sort = value;
    record.is_new ? '' : (record.is_edit = true);
    // this.$forceUpdate();
    this.table_data.detail.sort((a: any, b: any) => a.sort - b.sort);
    // this.sortTableList(this.ware_table_list);
  }

  // 更改活动价
  onPromotionPriceChange(record: any, value: number) {
    if (!value) {
      return;
    }
    if (Number(value) >= Number((record.product.price / 100).toFixed(2))) {
      this.$message.error('活动价格须低于原价');
      return;
    }
    console.log(this.table_data.detail)
    record.product.promotion.length > 0
      ? (record.product.promotion[0].promotion_price = value * 100)
      : (record.product.promotion = [{ promotion_price: value * 100 }]);
    record.is_new ? '' : (record.is_edit = true);
    // this.$forceUpdate();
  }

  // 选择商品分类
  onWareCategoryChange(value: any) {
    this.ware_modal.filter.category_id = value[value.length - 1];
  }

  // 打开商品模态窗
  openWareModal() {
    this.ware_modal.visible = true;
    // this.ware_modal.selected_id_list = [];
    // const ware_id_list = this.ware_modal.table_list.detail.map((item: any) => item.id);
    // this.table_data.detail.forEach((item: any) => {
    //   if (ware_id_list.indexOf(item.product_id) !== -1) {
    //     this.ware_modal.selected_id_list.push(item.product_id);
    //   }
    // });
  }

  // 确定添加商品
  async onWareModalOk() {
    if (!this.ware_modal.selected_id_list.length) {
      this.$message.error('请先添加商品');
      return;
    }
    let sort: number;
    if (this.is_edit) {
      sort = this.table_data.detail[this.table_data.detail.length - 1].sort + 1;
    } else {
      //this.table_data.detail = [];
      sort = 1;
    }
    const _data = this.ware_modal.selected_data;
    _data.map((item: any) => {
      const id_arr = this.table_data.detail.map((item: any) => item.product_id);
      if (
        this.ware_modal.selected_id_list.indexOf(item.id) === -1 &&
        id_arr.indexOf(item.id) !== -1 //反选去除新添加的
      ) {
        this.table_data.detail.splice(id_arr.indexOf(item.id), 1);
      }
      if (
        this.ware_modal.selected_id_list.indexOf(item.id) !== -1 &&
        id_arr.indexOf(item.id) === -1
      ) {
        //添加且编辑时去重
        this.table_data.detail.push({
          sort: sort,
          product_id: item.id,
          is_new: true,
          product: {
            ...item,
            promotion: [
              {
                promotion_price: 0,
              },
            ],
            sales: item.sale_count,
            viewpager_url: item.banner_url_list[0],
          },
        } as any);
        sort++;
      }
    });
    // if(this.is_edit){
    //   this.table_data.detail.filter((item: any)=>
    //     !(item.is_new===true&&this.ware_modal.selected_id_list.indexOf(item.product_id)===-1)
    //   )
    //   // const id_list=this.table_data.detail.map((item: any)=>item.id);
    //   // this.ware_modal.selected_id_list.map((item: any)=>{

    //   // })
    // }
    this.table_data.total = this.table_data.detail.length;
    // this.ware_modal.selected_data = [];
    // this.paginationOption();
    await this.closeWareModal();
  }

  // 编辑限时抢购
  @changeLoading(['is_loading'])
  async editLimitTime() {
    const add_product = this.table_data.detail.map((item: any) => {
      if (item.is_new) {
        return {
          product_id: item.product_id,
          sort: item.sort,
          promotion_price: item.product.promotion[0].promotion_price,
        } as any;
      } else {
        return;
      }
    });
    const edit_product = this.table_data.detail.map((item: any) => {
      if (item.is_edit) {
        return {
          product_id: item.product_id,
          sort: item.sort,
          promotion_price: item.product.promotion[0].promotion_price,
        } as any;
      }
    });
    const send_data = {
      subtype: +this.first_step_data.subtype,
      start_time: +this.first_step_data.start_time,
      end_time: +this.first_step_data.end_time,
      add_product: add_product.filter((item: any) => !!item),
      edit_product: edit_product.filter((item: any) => !!item),
      delete_product_ids: this.delete_product,
    };
    await editLimitTime(send_data, +this.$route.query.id);
  }
  //  async handlechange(data: any){
  //     console.log('2')

  //   }
  // ===================================================

  // 确认删除
  handleDeleteModalOk() {
    // 批量删除，即选中的都会被删除
    if (this.delete_modal.is_more) {
      this.table_data.detail = this.table_data.detail.filter((item: any) => {
        if (this.selected_id_list.indexOf(item.product_id) !== -1) {
          this.delete_product.push(
            this.selected_id_list[this.selected_id_list.indexOf(item.product_id)]
          );
        }
        return this.selected_id_list.indexOf(item.product_id) === -1;
      });
      // const delete_id_arr = this.selected_id_list;
      // this.table_data.detail.forEach((item: any) => {
      //   if (delete_id_arr.indexOf(item.id) !== -1) {
      //     this.delete_product.push(item.product_id);
      //   }
      // });
      // this.delete_product = [...new Set(this.delete_product)];
      // const product_arr = this.table_data.detail.map(item => item.id);
      // this.table_data.detail = this.table_data.detail.filter((item: any) => {
      //   return this.selected_id_list.indexOf(item.id) === -1;
      // });
      // this.ware_modal.selected_id_list = this.ware_modal.selected_id_list.filter((item: any) => {
      //   return this.delete_product.indexOf(item) === -1;
      // });
      this.selected_id_list = [];
    } else {
      const id = this.table_data.detail.map((item: any) => item.product_id); //id列表
      this.delete_product.push(
        this.table_data.detail[id.indexOf(this.delete_product_id)].product_id
      );
      this.table_data.detail.splice(id.indexOf(this.delete_product_id), 1);
      this.ware_modal.selected_id_list.forEach((item: any, index: number) => {
        if (item === this.delete_product_id) {
          this.ware_modal.selected_id_list.splice(index, 1);
        }
      });
      this.selected_id_list = [];
    }
    this.table_data.total = this.table_data.detail.length;
    this.delete_modal.visible = false;
  }

  @changeLoading(['is_loading'])
  async submit() {
    /* const no_price_arr = this.table_data.detail.filter((item: any) => {
      return !item.product.promotion.length || item.product.promotion[0].promotion_price === 0;
    });
    console.log(no_price_arr)
    if (no_price_arr.length > 0) {
      this.$message.error('请填写活动价');
      return;
    } */
    if (this.table_data.detail.length === 0) {
      this.$message.error('请先添加商品');
      return;
    }
    if (this.is_edit) {
      // 编辑
      await this.editLimitTime();
    } else {
      // 新增
      const res: any = await addLimitTime(this.getAddSendData());
      if (res.status !== 200) {
        return this.$message.error(res.message);
      }
    }
    this.$message.success('提交成功'); 
   
    this.$router.push('/operation/panic-buying');
    storage.removeItem('send_data');
    // this.$router.push({
    //   path:'/operation/panic-buying',
    //   query: { agpage: this.$route.query?.pe || '1' },
    // })
    this.globalFunction.fetchLimitTimeList() //更新数据
  }

  // 获取显示抢购中的商品
  @changeLoading(['is_loading'])
  async getPanicWare() {
    const res: any = await getWareList(+this.$route.query.id);
    // // this.table_data = initLimitTimeWareData();
    if (res.status !== 200) {
      this.$message.error(res.message);
      return;
    }
    this.table_data.total = res.data.length;
    this.table_data.detail = res.data;
    this.table_data.detail.forEach((item: any) => {
      item.is_edit = false;
      item.is_new = false;
      item.product.banner_url_list = item.product.viewpager_url.split(',');
    });
  }

  // 商品详情搜索
  @changeLoading(['is_loading'])
  async handleSearchWare() {
    this.ware_modal.is_loading = true;
    await this.fetchFilterWareList();
    this.ware_modal.is_loading = false;
  }

  // 获取商品分类列表
  async fetchWareCategoryList() {
    this.ware_category_list = await getSimpleCategory();
  }

  // 获取搜索后的商品列表
  async fetchFilterWareList(current: number = 1) {
    const send_data: any = {
      product_id: this.ware_modal.filter.ware_id,
      product_name: this.ware_modal.filter.ware_name,
      category_id: this.ware_modal.filter.category_id,
      is_show: this.ware_modal.filter.status === '0' ? '' : this.ware_modal.filter.status,
      config_limit_time: 0,
      page: 5,
      filter_promotion_product:1,
      is_vip:2
    };
    Object.keys(send_data).forEach((item: any) => {
      if (!send_data[item]) delete send_data[item];
    });

    this.ware_modal.table_list = await queryWare(current, send_data);
  }

  @changeLoading(['is_loading'])
  async changeWareList(data: any) {
    await this.fetchFilterWareList(data.current);
  }

  // 获取商品列表
  async fetchWareList(current: number = 1) {
    const res: any = await queryWare(current, {
      config_limit_time: 0,
      page: 5,
      filter_promotion_product: 1,
      is_vip:2
    });
    this.ware_modal.table_list = res;
    this.ware_modal.all_ware_data[this.ware_modal.next_page] = res.detail;
  }
  @changeLoading(['is_loading'])
  async created() {
    this.is_edit = !!this.$route.query.id && !this.$route.query.is_view;
    this.is_view = !!this.$route.query.is_view;
    this.first_step_data = storage.getItem('send_data');
    await this.fetchWareList();
    await this.fetchWareCategoryList();
    if (this.is_edit || this.is_view) {
      await this.getPanicWare();
    }
    // this.ware_modal.selected_id_list = this.table_data.detail.map((item: any) => {
    //   return item.product_id;
    // });
  }
}
