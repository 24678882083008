import { request as axios } from '@/util/request';
const API = {
  list: '/boss/operation/category',
};

// 首页分类列表数据接口
export interface HomeCategoryData {
  category: any;
  create_time: number;
  id: number;
  image_url: string;
  product_category_id: number;
  sort: number;
  update_time: number;
}
// 初始化首页分类列表
export function initHomeCategory(remote?: HomeCategoryData[]): HomeCategoryData[] {
  remote = remote || [];
  return remote;
}
// 首页分类列表
export async function getHomeCategoryList() {
  return await axios.get(API.list);
}

// 请求数据接口
interface SendData {
  add: AddData[];
  edit: EditData[];
}
interface AddData {
  sort: number;
  image_url: string;
  product_category_id: number;
  corner_mark: string;
}
interface EditData {
  id: number;
  sort: number;
  image_url: string;
  product_category_id: number;
  corner_mark: string;
}
// 保存
export async function saveHomeCategory(send_data: SendData) {
  return await axios.post(API.list, send_data);
}
