
import { Component, Watch, Mixins } from 'vue-property-decorator';
import { changeLoading } from '@/util/decorators';
import { getValidatorMap, Validate } from '@/mixin/validator';
// formatWareData,
import { WARE_STATUS_LIST, queryWare } from '@/api/ware/management';
import { getSimpleCategory } from '@/api/ware/category';
import { queryZoneById, addZone, updateZone } from '@/api/operation/special_zone';
import CustomModal from '@/component/custom-modal.vue';
import EditableCell from '@/component/editable-cell.vue';
import SingleImageUpload from '@/component/single-image-upload.vue';
import SamplePopover from '@/component/sample-popover.vue';
import storage from 'baimahu/storage';
import { useGlobalFunction } from '@/pinia/modules/globalFunction';
const uuidv4 = require('uuid/v4');

enum StyleEnum {
  one = 1,
  two,
  three,
}

interface Module {
  id?: number;
  title: string;
  banner_image_url: string;
  ware_table: any[];
  selected_id_list: number[];
  selected_data: any;
  is_new: boolean;
  deleted_ware_id_list: number[]; // 只在编辑的时候才会有这个
  validator: any;
}

// 上传文件的路径
const UPLOAD_DIR = {
  single: 'single',
  multiple: 'multiple',
};

@Component({
  components: {
    CustomModal,
    EditableCell,
    SamplePopover,
    SingleImageUpload,
  },
})
export default class AddZoneSecond extends Mixins(Validate) {
  globalFunction=useGlobalFunction().globalFunction;
  delete_id: number =0;
  is_loading: boolean = false;
  is_edit: boolean = false;
  MAX_DETAIL_TITLE: number = 8;
  MAX_MODULE_TITLE: number = 4;
  STYLE = StyleEnum;
  WARE_STATUS = WARE_STATUS_LIST;
  // 表单布局
  form_item_layout = {
    labelCol: { span: 3 },
    wrapperCol: { span: 21 },
  };
  // 模块布局
  module_item_layout = {
    labelCol: { span: 2 },
    wrapperCol: { span: 22 },
  };
  previous_form: any = {};
  form: any = {
    detail_page_title: '',
    banner_image_url: '',
    colour_number: '#ffffff',
    style: this.STYLE.one,
  };
  validator_list: any = [
    {
      field: 'detail_page_title',
      message: '请填写详情页标题',
    },
    {
      field: 'banner_image_url',
      message: '请上传banner图',
    },
    {
      field: 'colour_number',
      message: '请填写正确的底色',
    },
  ];
  validatorMap: { [field: string]: Validator } = getValidatorMap(this.validator_list);
  zone_box_id: number=0; //特色专区模块id
  preview_image: string = ''; // 预览图片的路径
  preview_visible: boolean = false; // 预览图片的模态框显示状态

  zone_id: number = 0;
  modules: Module[] = [];
  ware_category_list: any = [];
  deleted_module_id_list: number[] = [];

  ware_modal: SimpleModal = {
    title: '添加商品',
    visible: false,
    is_loading: false,
    filter: {
      ware_id: '',
      ware_name: '',
      category_id: undefined,
      status: '0',
    },
    ware_index: 0,
    next_page: 1, // 分页数
    all_ware_data: {}, // 所有的数据
    table_list: [], // 表格数据
    selected_id_list: [], // 选中项的 id
    selected_data: [], // 选中项的数据
  };
  delete_modal: SimpleModal = {
    title: '',
    visible: false,
    type: '',
    module_index: 0,
    ware_index: 0,
  };
  get sample_img(): string {
    return require('@/asset/img/logo-default.png');
  }

  get UPLOAD_DIR(): any {
    return UPLOAD_DIR;
  }

  get wareCurrentPageSelectedIdList(): number[] {
    // const selected_id_list = this.ware_modal.selected_id_list;
    // const current_page_ware_data_list = this.ware_modal.all_ware_data[this.ware_modal.next_page];

    // const current_page_selected_id_list: number[] = current_page_ware_data_list
    //   ? current_page_ware_data_list
    //       .filter((item: any) => !!selected_id_list.find((id: number) => id === item.id))
    //       .map((item: any) => item.id)
    //   : [];
    return this.ware_modal.selected_id_list;
  }
  paginationOption2(data: any){
    return {
      total: data.ware_table.length || 0,
      showTotal: () => `共${data.ware_table.length}条，每页${5}条`,
      pageSize: 5 || 1,
      current_page: data.current || 1,
    };
  }

  get wareModalRowSelection(): object {
    return {
      selectedRowKeys: this.wareCurrentPageSelectedIdList,
      onSelect: (record: any, selected: boolean) => {
        if (selected) {
          if (this.ware_modal.selected_id_list.indexOf(record.id) === -1) {
            this.ware_modal.selected_data.push(record);
            this.ware_modal.selected_id_list.push(record.id);
          }
        } else {
          const index = this.ware_modal.selected_id_list.indexOf(record.id);
          this.ware_modal.selected_id_list.splice(index, 1);
          this.ware_modal.selected_data.splice(index, 1);
        }
      },
      onSelectAll: (selected: boolean, selected_rows: any, change_rows: any) => {
        if (selected) {
          this.ware_modal.selected_id_list = [
            ...new Set([
              ...this.ware_modal.selected_id_list,
              ...selected_rows.map((item: any) => item.id),
            ]),
          ];
          const id_list = this.ware_modal.selected_data.map((item: any) => item.product_id);
          this.ware_modal.table_list.detail.forEach((item: any) => {
            if (id_list.indexOf(item.id) === -1) {
              this.ware_modal.selected_data.push(item);
            }
          });
        } else {
          this.ware_modal.selected_id_list = this.ware_modal.selected_id_list.filter(
            (id: number) => !change_rows.find((item: any) => item.id === id)
          );
        }
        // this.ware_modal.selected_data = null;
        // this.ware_modal.selected_data = selected_rows;
      },
    };
  }

  @Watch('ware_modal', { deep: true })
  changeWareModal() {
    this.addParentCategory(this.ware_modal.table_list.detail, 1);
  }

  @Watch('modules', { deep: true })
  changeModules() {
    this.modules.map((item: any) => {
      this.addParentCategory(item.ware_table, 2);
    });
  }

  // 给商品添加父分类
  addParentCategory(arr: any[], type: number) {
    arr.forEach((ware: any) => {
      /* 由于商品模态窗和模块中的商品数据结构不一致
      因此区分添加父级分类
      1为商品模态窗
      2为模块中的商品 */
      this.ware_category_list.map((ware_category: any) => {
        if (type === 1) {
          if (ware_category.id === ware.category.parent_id && ware.category.parent_id !== 0) {
            ware.category.parent_category = `${ware_category.name} /`;
          }
        } else {
          if (
            ware_category.id === ware.product.category.parent_id &&
            ware.product.category.parent_id !== 0
          ) {
            ware.product.category.parent_category = `${ware_category.name} /`;
          }
        }
      });
    });
  }

  // 通用图片上传校验
  beforeCommonUpload(file: any) {
    if (file.size / 1024 / 1024 > 2) {
      this.$message.error('上传图片大小不能超过2M');
      return false;
    }
  }

  paginationOption(data: RemotePagination<any>) {
    return {
      total: data.total || 0,
      showTotal: () => `共${data.total}条，每页${data.limit}条`,
      pageSize: data.limit || 1,
      current_page: data.current || 1,
    };
  }

  getModuleValidator() {
    const fields = ['title', 'banner_image_url', 'ware_table'];
    const obj: any = {};
    fields.map((field: string) => {
      obj[field] = {
        help: '',
        status: 'success',
        is_valid: false,
      };
    });
    return obj;
  }

  sortTableList(table: any) {
    table.sort((a: any, b: any) => a.module_sort - b.module_sort);
  }

  getProductData(data: any) {
    const add_product: any = [];
    const edit_product: any = [];
    data.map((item: any) => {
      if (item.is_new === false) {
        // 编辑的商品
        if (item.is_edit) {
          const _product = {
            id: item.module_ware_id,
            sort: item.module_sort,
          };
          edit_product.push(_product);
        }
      } else {
        // 新增的商品
        const _product = {
          product_id: item.id,
          sort: item.module_sort,
        };
        add_product.push(_product);
      }
    });
    return {
      add_product,
      edit_product,
    };
  }

  getModuleData() {
    const add_box: any = [];
    const edit_box: any = [];
    this.modules.map((item: Module) => {
      if (item.is_new === false) {
        // 编辑的模块
        const _box = {
          id: item.id,
          title: item.title,
          banner_image_url:
            item.banner_image_url.indexOf('http') >= 0
              ? item.banner_image_url
              : `https:${item.banner_image_url}`,
          add_product: this.getProductData(item.ware_table).add_product,
          edit_product: this.getProductData(item.ware_table).edit_product,
          delete_product_id: item.deleted_ware_id_list,
        };
        edit_box.push(_box);
      } else {
        const _box = {
          title: item.title,
          banner_image_url:
            item.banner_image_url.indexOf('http') >= 0
              ? item.banner_image_url
              : `https:${item.banner_image_url}`,
          product: this.getProductData(item.ware_table).add_product,
        };
        // 新增的模块
        add_box.push(_box);
      }
    });
    return {
      add_box,
      edit_box,
    };
  }

  getBaseSendData() {
    const send_data = {
      title: this.previous_form.title,
      subtitle: this.previous_form.subtitle,
      home_image_url:
        this.previous_form.home_image_url.indexOf('http') === -1
          ? `https:${this.previous_form.home_image_url}`
          : this.previous_form.home_image_url,
      home_image_plus_url: this.previous_form.home_image_plus_url
        ? this.previous_form.home_image_plus_url.indexOf('http') === -1
          ? `http:${this.previous_form.home_image_plus_url}`
          : this.previous_form.home_image_plus_url
        : '',
      detail_page_title: this.form.detail_page_title,
      banner_image_url:
        this.form.banner_image_url.indexOf('http') === -1
          ? `http:${this.form.banner_image_url}`
          : this.form.banner_image_url,
      colour_number: this.form.colour_number,
      style: this.form.style,
    };
    return send_data;
  }

  getAddSendData() {
    const res = this.getBaseSendData();
    return {
      ...res,
      box: this.getModuleData().add_box,
    };
  }

  getUpdateSendData() {
    const res = this.getBaseSendData();
    const add_box = this.getModuleData().add_box;
    const edit_box = this.getModuleData().edit_box;
    const delete_box_id = this.deleted_module_id_list;
    return {
      ...res,
      add_box,
      edit_box,
      delete_box_id,
    };
  }

  // 显示模态窗，改变模态窗中的图片路径
  handlePreview(imageUrl: string) {
    this.preview_image = imageUrl;
    this.preview_visible = true;
  }
  handlePreviewCancel() {
    this.preview_visible = false;
  }

  // 取消添加商品
  async handleWareModalCancel() {
    this.ware_modal.visible = false;
    this.clearWareModalForm();
    await this.fetchFilterWareList();
  }

  // 清空添加商品模态窗中的表单数据
  clearWareModalForm() {
    this.ware_modal.filter = {
      ware_id: '',
      ware_name: '',
      category_id: undefined,
      status: '0',
    };
  }

  // 确定添加商品
  async handleWareModalOk() {
    if (this.wareCurrentPageSelectedIdList.length <= 0) {
      return this.$message.error('请勾选商品');
    }
    const _module = this.modules[this.ware_modal.ware_index];
    const _modal = this.ware_modal;
    let sort =
      _module.ware_table.length > 0
        ? _module.ware_table[_module.ware_table.length - 1].module_sort
        : 0;
    _module.selected_data = JSON.parse(JSON.stringify(_modal.selected_data));
    _module.selected_data.map((item: any) => {
      // 如果没有就添加
      if (_module.selected_id_list.indexOf(item.product_id) === -1) {
        _module.ware_table.push({
          product: {
            ...item,
            viewpager_url: item.banner_url_list.join(),
          },
          product_id:item.id,
          id: item.id,
          module_sort: sort + 1,
        });
        sort = sort + 1;
      }
    });
    _module.selected_id_list = JSON.parse(JSON.stringify(_modal.selected_id_list));
    _module.ware_table = _module.ware_table.filter((item: any) => {
      if (~_module.selected_id_list.indexOf(item.product.id)) {
        return true;
      }
    });
    const obj: any={}
    _module.ware_table=_module.ware_table.reduce((item,next)=>{
      obj[next.product_id]?'':obj[next.product_id]=true&&item.push(next);
      return item;
    },[])
    _modal.visible = false;
    this.clearWareModalForm();
    await this.fetchFilterWareList();
    

  }

  // 确定删除
  handleDeleteModalOk() {
    const _fail_modal = this.delete_modal;
    const _module = this.modules[_fail_modal.module_index];
    // const _ware = _module.ware_table[_fail_modal.ware_index];
    if (_fail_modal.type === 'ware') {
      // 删除商品
      const id_list=this.modules[this.delete_modal.module_index].ware_table.map((item: any)=>item.id);
      this.modules[this.delete_modal.module_index].deleted_ware_id_list.push(this.delete_id);
      _module.ware_table.splice(id_list.indexOf(this.delete_id), 1);
      const deleted_id = _module.selected_id_list.splice(id_list.indexOf(this.delete_id), 1)[0];
      _module.selected_data.map((item: any, index: number) => {
        if (item.id === deleted_id) {
          _module.selected_data.splice(index, 1);
        }
      });
    } else {
      // 删除模块
      if (_module.ware_table.length === 0) {
        this.modules.splice(_fail_modal.module_index, 1);
        _module.id && this.deleted_module_id_list.push(_module.id);
      } else {
        this.$message.error('该模块还有商品为删除，请删除后再操作');
      }
    }
    _fail_modal.visible = false;
  }

  // // 商品详情搜索
  @changeLoading(['is_loading'])
  async handleSearchWare() {
    this.ware_modal.is_loading = false;
    await this.fetchFilterWareList();
    this.ware_modal.is_loading = false;
  }

  // 切换分页
  @changeLoading(['is_loading'])
  async handleChange(data: any) {
    //console.log(data.current)
    await this.fetchFilterWareList(data.current);
  }

  // 删除模块
  onDeleteModule(index: number) {
    this.delete_modal.type = 'module';
    this.delete_modal.module_index = index;
    this.delete_modal.visible = true;
  }

  // // 添加商品
  async onWareModalShow(index: number) {
    const _module: any = this.modules[index];
    this.zone_box_id=_module.id;
    const _modal = this.ware_modal;
    _modal.selected_id_list = JSON.parse(JSON.stringify(_module.selected_id_list));
    _modal.selected_data = JSON.parse(JSON.stringify(_module.selected_data));
    _modal.ware_index = index;
    const res: any = await queryWare(1, {
      page: 5,
      zone_box_id:this.zone_box_id
    });
    this.ware_modal.table_list = res;
    this.ware_modal.all_ware_data[this.ware_modal.next_page] = res.detail;
    _modal.visible = true;
  }

  // // 选择商品分类
  onWareCategoryChange(value: any) {
    this.ware_modal.filter.category_id = value[value.length - 1];
  }

  // 删除商品
  onDeleteWare(record: any,index: number, module_index: number) {
    this.delete_modal.type = 'ware';
    this.delete_id=record.id;
    this.delete_modal.ware_index = index;
    this.delete_modal.module_index = module_index;
    this.delete_modal.visible = true;
  }

  // 更改排序值
  onSortChange(index: number, module_index: number, value: number) {
    const _ware_table = this.modules[module_index].ware_table;
    _ware_table[index].module_sort = value;
    if (_ware_table[index].is_new === false) {
      // 如果不是新添加的商品，就给它标记上编辑过
      _ware_table[index].is_edit = true;
    }
    this.sortTableList(_ware_table);
  }

  // 新增模块
  onAddModule() {
    const module = {
      _key: uuidv4(),
      title: '',
      banner_image_url: '',
      ware_table: [],
      selected_id_list: [],
      selected_data: [],
      deleted_ware_id_list: [],
      is_new: true, // 是否是新添加的模块
      validator: this.getModuleValidator(),
    };
    this.modules.push(module);
  }

  // 给模块中的表单设置验证
  setModuleValidatorStatus(item: any, type: string, help: string) {
    if (item[type].length === 0) {
      item.validator[type].help = help;
      item.validator[type].status = 'error';
      item.validator[type].is_valid = false;
    } else {
      item.validator[type].help = '';
      item.validator[type].status = 'success';
      item.validator[type].is_valid = true;
    }
  }

  onModulesChange() {
    return this.modules.filter((item: any) => {
      this.setModuleValidatorStatus(item, 'title', '请填写模块标题');
      this.setModuleValidatorStatus(item, 'banner_image_url', '请上传模块banner图');
      this.setModuleValidatorStatus(item, 'ware_table', '请至少添加一个商品');
      return !(item.title && item.banner_image_url && item.ware_table.length);
    });
  }
  // 提交
  @changeLoading(['is_loading'])
  async submit() {
    this.onModulesChange();
    if (this.onModulesChange().length) {
      this.$message.error('请填入必填信息');
      return;
    }
    const no_sort_module = this.modules.filter((item: any) => {
      return item.ware_table.map((item2: any) => {
        return !item2.module_sort;
      })[0];
    });
    if (no_sort_module.length) {
      this.$message.error('请填写排序值');
      return;
    }
    this.getUpdateSendData();
    // const is_validate = this.onModulesChange()[0];
    if (!this.validateCommit()) {
      return;
    }
    if (this.is_edit) {
      // 编辑
      const res: any = await updateZone(this.zone_id, this.getUpdateSendData());
      if (res.status !== 200) {
        return this.$message.error(res.message);
      }
    } else {
      // 新增
      const res: any = await addZone(this.getAddSendData());
      if (res.status !== 200) {
        this.$message.error(res.message);
        return;
      }
    }
    this.$message.success('提交成功');
    this.$router.push('/operation/special-zone');
    storage.removeItem('zone_first');
    this.globalFunction.fetchSpecialZoneData()
    // this.$router.push({
    //   path:'/operation/special-zone',
    //   query:{ agpage: this.$route.query?.pe || '1' }
    // })
  }

  // // 获取搜索后的商品列表
  async fetchFilterWareList(current: number = 1) {
    const send_data = {
      product_id: this.ware_modal.filter.ware_id,
      product_name: this.ware_modal.filter.ware_name,
      category_id: this.ware_modal.filter.category_id,
      is_show: this.ware_modal.filter.status === '0' ? '' : this.ware_modal.filter.status,
      page: 5,
      zone_box_id:this.zone_box_id
    };
    console.log(send_data)
    send_data.category_id==undefined?delete send_data.category_id:send_data.category_id
    send_data.product_id==''? delete send_data.product_id: send_data.product_id
    send_data.product_name==''? delete send_data.product_name: send_data.product_name
    send_data.is_show==''? delete send_data.is_show: send_data.is_show
    this.ware_modal.table_list = await queryWare(current, send_data);
    this.ware_modal.table_list.detail.forEach((item: any) => {
      item.price * 100;
    });
  }

  // 获取专区详情
  async fetchZoneData(id: number) {
    const res = await queryZoneById(id);
    this.formatZoneData(res);
  }

  formatZoneData(res: SpecialZoneData) {
    this.form.detail_page_title = res.detail_page_title;
    this.form.banner_image_url = res.banner_image_url;
    if(res.colour_number.indexOf("#")!==-1){
      this.form.colour_number = res.colour_number
    }else{
      this.form.colour_number = "#ffffff"
    }
    
    this.form.style = res.style;
    this.modules = res.box.map((item: any) => {
      const selected_data: any = [];
      const selected_id_list: number[] = [];
      item.zone_product.map((product: any) => {
        selected_id_list.push(product.product_id);
        selected_data.push(this.formatWareData(product));
      });
      return {
        _key: uuidv4(),
        id: item.id,
        title: item.title,
        banner_image_url: item.banner_image_url,
        selected_id_list: selected_id_list,
        selected_data: selected_data,
        ware_table: selected_data,
        deleted_ware_id_list: [],
        is_new: false,
        validator: this.getModuleValidator(),
      };
    });
    this.modules.forEach((item: any) => {
      item.ware_table.forEach((item2: any) => {
        item2.product.banner_url_list = item2.product.viewpager_url.split(',');
      });
    });
  }

  // 拦截原 formatWareData 函数，添加一个新的属性
  formatWareData(product: any) {
    return {
      ...product,
      module_sort: product.sort,
      module_ware_id: product.id,
      is_new: false, // 是否是新添加的商品
    };
  }

  @changeLoading(['is_loading'])
  async created(current: number = 1) {
    this.previous_form = storage.getItem('zone_first');
    // 获取商品列表
    
    // this.ware_modal.table_list = res;
    // this.ware_modal.all_ware_data[this.ware_modal.next_page] = res.detail;
    // 获取分类列表
    this.ware_category_list = await getSimpleCategory();
    // 编辑
    if (this.$route.query.id) {
      this.zone_id = +this.$route.query.id;
      this.is_edit = true;
      await this.fetchZoneData(this.zone_id);
    }
  }
}
