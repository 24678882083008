import { Moment } from 'moment';
const urlSafeBase64 = require('urlsafe-base64');
const getUrl = require('bmh/url-with-param');
const getSuffix = require('bmh/get-suffix');
const dateFormat = require('bmh/date-format');

export function date(data?: Moment): string {
  if (!data) {
    return '';
  }
  return data.format('YYYY-MM-DD');
}

export function time(data?: Moment): string {
  if (!data) {
    return '';
  }
  return data.format('HH:mm:ss');
}

export function dateTime(data?: Moment): string {
  if (!data) {
    return '';
  }
  return data.format('YYYY-MM-DD HH:mm:ss');
}

export function simpleArrayOptions(array: any[]) {
  return array.map((item: any) => ({ label: item, value: item }));
}

export function imageUrlWithWatermark(url: string, watermarkText: string): string {
  return getUrl(url, {
    'x-oss-process': `image/format,jpg/watermark,text_${urlSafeBase64.encode(
      watermarkText
    )},color_ffffff`,
  });
}

// handle webp
export function imgUrl(url: string): string {
  const suffix = getSuffix(url).substr(1) || '';
  if (!suffix || ['jpeg', 'jpg', 'png'].indexOf(suffix.toLowerCase()) > -1) {
    return url;
  }
  return getUrl(url, { 'x-oss-process': 'image/format,jpg' });
}

export function holdDate(date: any, duration: any, options: any) {
  /**
   * 时间格式转换
   * @param {String|Date} date 开始日期
   * @param {Number} duration 持续天数
   * @param {Object} options 选项
   * @param {Boolean|Number} options.force_year 强制显示年月日
   * @returns {String} 格式化后的日期
   */
  let start_format = '%Y年%m月%d日';
  let end_format = '%d日';
  const start_time = new Date(date).getTime();
  const end_time = new Date(date).getTime() + duration * 3600 * 24 * 1000;
  options = options || {};

  if (options.force_year) {
    start_format = end_format = '%Y年%m月%d日';
  } else if (duration >= 1) {
    if (dateFormat(start_time, '%m') !== dateFormat(end_time, '%m')) {
      end_format = '%m月%d日';
    }
    if (dateFormat(start_time, '%Y') !== dateFormat(end_time, '%Y')) {
      start_format = end_format = '%Y年%m月%d日';
    }
  }
  if (options.simple) {
    start_format = start_format.replace(/[\u4e00-\u9fa5]/g, '-');
    end_format = end_format.replace(/[\u4e00-\u9fa5]/g, '-');
  }

  const start = dateFormat(start_time, start_format, false);
  const end = dateFormat(end_time, end_format, false);
  return `${start}${duration >= 1 ? `-${end}` : ''}`;
}
