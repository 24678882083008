
import { Component, Mixins, Watch } from 'vue-property-decorator';
import SingleImageUpload from '@/component/single-image-upload.vue';
import CustomModal from '@/component/custom-modal.vue';
import { Validate, getValidatorMap } from '@/mixin/validator';
import { changeLoading } from '@/util/decorators';
import { queryZone, queryZoneById } from '@/api/operation/special_zone';
import { getSimpleCategory } from '@/api/ware/category';
import { WARE_STATUS_LIST, queryWare } from '@/api/ware/management';
import { NAVIGATION_TYPE, queryBannerById, addBanner, updateBanner } from '@/api/operation/banner';
import { useGlobalFunction } from '@/pinia/modules/globalFunction';
import moment from 'moment';

const UPLOAD_DIR = {
  banner_image: 'operation/banner_image',
};

@Component({
  components: {
    CustomModal,
    SingleImageUpload,
  },
})
export default class AddBanner extends Mixins(Validate) {
  isForced: boolean = false; // 是否强制上下架过
  globalFunction = useGlobalFunction().globalFunction;
  form_item_layout: any = {
    labelCol: { span: 3 },
    wrapperCol: { span: 21 },
  };
  form: any = {
    banner_image_url: '',
    banner_name: '',
    navigation_type: 0, // 0 表示内部跳转这个按钮被选中
    inner_page_type: 1,
    remark: '', // 专区/商品 id
    remark_name: '', // 跳转页面的标题
    position: 1, // 跳转位置
    time: [], // 开始时间-结束时间
  };
  validator_list: any = [
    {
      field: 'banner_image_url',
      message: '请上传banner图',
    },
    {
      field: 'banner_name',
      message: '请填写banner名称',
    },
  ];
  navigation_validator: any = {
    help: '',
    status: 'success',
    is_valid: false,
  };
  validatorMap: { [field: string]: Validator } = getValidatorMap(this.validator_list);
  MAX_LENGTH: number = 6;
  is_loading: boolean = false;
  is_edit: boolean = false;
  preview_image: string = ''; // 示例图片预览路径
  preview_visible: boolean = false; // 控制示例图片显示和隐藏
  banner_id: number = 0; // 页面 id

  UPLOAD_DIR = UPLOAD_DIR;
  NAVIGATION_TYPE = NAVIGATION_TYPE;
  WARE_STATUS: any = WARE_STATUS_LIST;
  ZONE_PER_PAGE: number = 5;
  WARE_PER_PAGE: number = 10;

  special_table_data: any = [];
  ware_table_data: any = [];
  ware_category_list: any = [];

  outer_navigation_link: string = ''; // 外部跳转页面链接

  special_modal: SimpleModal = {
    title: '选择特色专区',
    visible: false,
    is_loading: false,
    zone_name: '', // 特色专区名称
    next_page: 1, // 分页数
    table_list: [], // 表格数据
    all_table_data: [], // 所有的数据
    selected_id: [], // 选中项的 id
    selected_data: {}, // 选中项的数据
    temp: {}, // 临时存储选中项 id 和 数据
  };
  ware_modal: SimpleModal = {
    title: '选择商品详情页',
    visible: false,
    is_loading: false,
    filter: {
      ware_id: '',
      ware_name: '',
      category_id: undefined,
      status: '0',
    },
    next_page: 1, // 分页数
    table_list: [], // 表格数据
    selected_id: [], // 选中项的 id
    selected_data: {}, // 选中项的数据
    temp: {}, // 临时存储选中项 id 和 数据
  };
  color: any = '#fff';

  get is_show_special_table(): boolean {
    if (this.special_table_data.length > 0) {
      this.navigation_validator.help = '';
      this.navigation_validator.status = 'success';
    }
    return this.special_table_data.length > 0;
  }

  get is_show_ware_table(): boolean {
    if (this.ware_table_data.length > 0) {
      this.navigation_validator.help = '';
      this.navigation_validator.status = 'success';
    }
    return this.ware_table_data.length > 0;
  }

  get inner_page_navigation(): boolean {
    return this.form.navigation_type >= 0 && this.form.navigation_type <= 4;
  }

  get specialModalRowSelection(): any {
    return {
      columnTitle: ' ',
      selectedRowKeys: [this.special_modal.selected_id],
      onSelect: (record: any, selected: boolean) => {
        if (selected) {
          this.special_modal.selected_id = record.id;
          this.special_modal.selected_data = record;
        } else {
          this.special_modal.selected_id = [];
          this.special_modal.selected_data = {};
        }
      },
    };
  }

  get wareModalRowSelection(): any {
    return {
      columnTitle: ' ',
      selectedRowKeys: [this.ware_modal.selected_id],
      onSelect: (record: any, selected: boolean) => {
        if (selected) {
          this.ware_modal.selected_id = record.id;
          this.ware_modal.selected_data = record;
        } else {
          this.ware_modal.selected_id = [];
          this.ware_modal.selected_data = {};
        }
      },
    };
  }
  @Watch('outer_navigation_link')
  onOuterLinkChange() {
    this.validateNavigationType();
  }
  click() {
    console.log(this.form);
  }
  beforeCommonUpload(file: any) {
    if (file.size / 1024 / 1024 > 2) {
      this.$message.error('上传图片大小不能超过2M');
      return false;
    }
  }

  paginationOption(data: RemotePagination<any>) {
    return {
      total: data.total || 0,
      showTotal: () => `共${data.total}条，每页${data.limit}条`,
      pageSize: data.limit || 1,
      current: +data.current || 1,
    };
  }

  // 校验跳转类型
  validateNavigationType(): boolean {
    if (this.form.navigation_type === this.NAVIGATION_TYPE.outer_page) {
      const _is_valid = this.outer_navigation_link.length > 0;
      if (_is_valid) {
        this.navigation_validator.help = '';
        this.navigation_validator.status = 'success';
      } else {
        this.navigation_validator.help = '请填写跳转页面链接';
        this.navigation_validator.status = 'error';
      }
      return _is_valid;
    }
    if (this.form.navigation_type === 0) {
      const _inner_type = this.form.inner_page_type;
      if (_inner_type === this.NAVIGATION_TYPE.special) {
        if (this.is_show_special_table) {
          this.navigation_validator.help = '';
          this.navigation_validator.status = 'success';
        } else {
          this.navigation_validator.help = '请选择需要跳转的页面';
          this.navigation_validator.status = 'error';
        }
        return this.is_show_special_table;
      }
      if (_inner_type === this.NAVIGATION_TYPE.ware) {
        if (this.is_show_ware_table) {
          this.navigation_validator.help = '';
          this.navigation_validator.status = 'success';
        } else {
          this.navigation_validator.help = '请选择需要跳转的页面';
          this.navigation_validator.status = 'error';
        }
        return this.is_show_ware_table;
      }
    }
    return true;
  }

  back() {
    this.$router.go(-1);
  }

  // 显示模态窗，改变模态窗中的图片路径
  handlePreview(imageUrl: string) {
    this.preview_image = imageUrl;
    this.preview_visible = true;
  }
  // 关闭模态窗
  handlePreviewCancel() {
    this.preview_visible = false;
  }

  // 取消的时候将之前缓存的数据重新赋值
  async handleSpecialModalCancel() {
    const _modal = this.special_modal;
    _modal.selected_id = _modal.temp.selected_id;
    _modal.selected_data = _modal.temp.selected_data;
    _modal.visible = false;
    this.special_modal.zone_name = '';
    await this.handleSearchSpecialZone();
  }

  // 搜索特色专区
  async handleSearchSpecialZone() {
    this.special_modal.is_loading = true;
    this.special_modal.next_page = 1;
    await this.fetchSpecialZoneData(this.special_modal.next_page);
    this.special_modal.is_loading = false;
  }

  async handleWareModalCancel() {
    const _modal = this.ware_modal;
    _modal.selected_id = _modal.temp.selected_id;
    _modal.selected_data = _modal.temp.selected_data;
    _modal.visible = false;
    await this.clearFormData();
  }

  // 确定选择特色专区
  async handleSpecialModalOk() {
    if (this.specialModalRowSelection.selectedRowKeys[0].length <= 0) {
      this.$message.error('请勾选商品');
      return;
    }
    this.special_table_data = null;
    if (this.special_modal.selected_id.length === 0) {
      this.special_table_data = [];
    } else {
      this.special_table_data = [this.special_modal.selected_data];
      // console.log('special1', this.special_table_data);
    }
    this.form.remark = this.special_table_data[0] ? this.special_table_data[0].id : '';
    this.form.remark_name = this.special_table_data[0] ? this.special_table_data[0].title : '';
    this.special_modal.zone_name = '';
    await this.handleSearchSpecialZone();
    this.special_modal.visible = false;
  }

  // 特色专区表格分页
  async handleSpecialModalTableChange(pagination: any) {
    this.special_modal.is_loading = true;
    this.special_modal.next_page = pagination.current;
    await this.fetchSpecialZoneData(this.special_modal.next_page);
    this.special_modal.is_loading = false;
  }

  // 商品详情搜索
  async handleSearchWare() {
    this.ware_modal.is_loading = true;
    this.ware_modal.next_page = 1;
    await this.fetchWareList(this.ware_modal.next_page);
    this.ware_modal.is_loading = false;
  }

  // 确定选择商品详情页
  async handleWareModalOk() {
    if (this.wareModalRowSelection.selectedRowKeys[0].length <= 0) {
      this.$message.error('请勾选商品');
      return;
    }
    this.ware_table_data = null;
    if (this.ware_modal.selected_id.length === 0) {
      this.ware_table_data = [];
    } else {
      this.ware_table_data = [this.ware_modal.selected_data];
    }
    this.form.remark = this.ware_table_data[0] ? this.ware_table_data[0].id : '';
    this.form.remark_name = this.ware_table_data[0] ? this.ware_table_data[0].title : '';
    this.ware_table_data.forEach((ware: any) => {
      this.ware_category_list.map((ware_category: any) => {
        if (ware_category.id === ware.category.parent_id && ware.category.parent_id !== 0) {
          ware.category.parent_category = `${ware_category.name} /`;
        }
      });
    });
    this.clearFormData();
  }

  // 清空商品详情页表单数据
  async clearFormData() {
    this.ware_modal.filter.ware_name = '';
    this.ware_modal.filter.ware_id = '';
    this.ware_modal.filter.status = '0';
    this.ware_modal.filter.category_id = undefined;
    await this.handleSearchWare();
    this.ware_modal.visible = false;
  }

  // 内部跳转单选项发生变化
  onInnerRadioGroupChange() {
    this.special_table_data = [];
    this.special_modal.selected_id = [];
    this.special_modal.selected_data = {};
    this.ware_table_data = [];
    this.ware_modal.selected_id = [];
    this.ware_modal.selected_data = {};
    this.form.remark = '';
  }

  // 跳转类型单选项发生变化
  onNavTypeRadioGroupChange() {
    this.onInnerRadioGroupChange();
    this.navigation_validator.help = '';
    this.navigation_validator.status = 'success';
  }

  // 选择商品分类
  onWareCategoryChange(value: any) {
    this.ware_modal.filter.category_id = value[value.length - 1];
  }

  // 弹窗出现的时候将数据缓存
  onSpecialModalShow() {
    this.special_modal.temp = {
      selected_id: this.special_modal.selected_id,
      selected_data: this.special_modal.selected_data,
    };
    this.special_modal.visible = true;
  }

  onWareModalShow() {
    this.ware_modal.temp = {
      selected_id: this.ware_modal.selected_id,
      selected_data: this.ware_modal.selected_data,
    };
    this.ware_modal.visible = true;
  }

  onWareModalTableChange(pagination: any) {
    this.ware_modal.next_page = pagination.current;
    this.fetchWareList(this.ware_modal.next_page);
  }

  async onSubmit() {
    if (!this.validateCommit() || !this.validateNavigationType()) {
      return;
    }
    if (!this.validateNavigationType()) {
      return;
    }
    let banner_url;
    if (this.form.banner_image_url.indexOf('http') !== -1) {
      banner_url = this.form.banner_image_url;
    } else {
      banner_url = `https:${this.form.banner_image_url}`;
    }
    const title = this.form.banner_name;
    const type =
      this.form.navigation_type === 0 ? this.form.inner_page_type : this.form.navigation_type;
    const remark =
      this.form.navigation_type === NAVIGATION_TYPE.outer_page
        ? this.outer_navigation_link
        : this.form.remark;
    const start_time = this.form.time[0] ? Math.floor(+this.form.time[0].format('x') / 1000) : '';
    const end_time = this.form.time[1] ? Math.floor(+this.form.time[1].format('x') / 1000) : '';
    const remark_name = this.form.remark_name;
    const position = this.form.position;
    console.log(this.form);
    if (this.is_edit) {
      // 编辑
      const res: any = await updateBanner(
        this.banner_id,
        banner_url,
        title,
        type,
        remark,
        remark_name,
        this.color,
        position,
        start_time,
        end_time
      );
      if (res.status !== 200) {
        this.$message.error(res.message);
        return;
      }
    } else {
      // 新增
      const res: any = await addBanner(
        banner_url,
        title,
        type,
        remark,
        remark_name,
        this.color,
        position,
        start_time,
        end_time
      );
      if (res.status !== 200) {
        this.$message.error(res.message);
        return;
      }
    }
    this.$message.success('提交成功');
    this.$router.push('/operation/banner-list');
    this.globalFunction.fetchBannerData();
  }

  // 获取特色专区数据
  async fetchSpecialZoneData(current: number = 1) {
    this.special_modal.table_list = await queryZone(current, {
      title: this.special_modal.zone_name,
      limit: this.ZONE_PER_PAGE,
    });
  }

  // 获取专区详情
  async fetchSpecialZoneById(id: number) {
    return await queryZoneById(id);
  }

  // 获取商品分类列表
  async fetchWareCategoryList() {
    this.ware_category_list = await getSimpleCategory();
  }

  // 获取商品列表
  async fetchWareList(current: number = 1) {
    const send_data = {
      product_id: this.ware_modal.filter.ware_id,
      product_name: this.ware_modal.filter.ware_name,
      category_id: this.ware_modal.filter.category_id,
      is_show: this.ware_modal.filter.status === '0' ? '' : this.ware_modal.filter.status,
      limit: 5,
    };
    //判断参数是否为空，是就不传
    if (this.ware_modal.filter.ware_id == '') {
      delete send_data.product_id;
    }
    if (this.ware_modal.filter.ware_name == '') {
      delete send_data.product_name;
    }
    if (this.ware_modal.filter.category_id == undefined) {
      delete send_data.category_id;
    }
    if (send_data.is_show == '') {
      delete send_data.is_show;
    }
    this.ware_modal.table_list = await queryWare(current, send_data);
    this.ware_modal.table_list.detail.forEach((ware: any) => {
      this.ware_category_list.map((ware_category: any) => {
        if (ware_category.id === ware.category.parent_id && ware.category.parent_id !== 0) {
          ware.category.parent_category = `${ware_category.name} /`;
        }
      });
    });
  }

  // 获取 banner 数据
  async fetchBannerData(id: number) {
    const res = await queryBannerById(id);
    this.isForced = res.data.forced == 0 ? false : true;
    const _data = res.data;
    console.log('_data', _data);
    this.form.banner_image_url = _data.banner_url;
    this.form.banner_name = _data.title;
    this.form.time[0] = _data.start_time ? moment(_data.start_time * 1000) : 0;
    this.form.time[1] = _data.end_time ? moment(_data.end_time * 1000) : 0;
    this.color = _data.bg_color;

    if (_data.jump_type === 2) {
      const wareRes = await queryWare(1, {
        product_id: _data.remark,
      });
      this.ware_table_data.push(wareRes.detail[0]);
      // console.log(this.ware_table_data);
    }
    if (_data.jump_type === 1) {
      const specialRes = await queryZone(1, {
        id: +_data.remark,
      });
      this.special_table_data.push(specialRes.detail[0]);
      console.log('special', this.special_table_data);
    }
    switch (_data.jump_type) {
      case this.NAVIGATION_TYPE.special:
        this.special_modal.selected_id = +_data.remark;
        this.special_modal.selected_data = await this.fetchSpecialZoneById(+_data.remark);
        this.form.navigation_type = 0;
        this.form.inner_page_type = _data.jump_type;
        // this.handleSpecialModalOk();
        break;
      case this.NAVIGATION_TYPE.ware:
        this.ware_modal.selected_id = +_data.remark;
        this.ware_modal.table_list.detail.map((item: any) => {
          if (item.id === +_data.remark) {
            this.ware_modal.selected_data = item;
          }
        });
        this.form.navigation_type = 0;
        this.form.inner_page_type = _data.jump_type;
        break;
      case this.NAVIGATION_TYPE.limited_time:
      case this.NAVIGATION_TYPE.rich_scan:
      case this.NAVIGATION_TYPE.tab:
        this.form.navigation_type = 0;
        this.form.remark = '';
        this.form.remark_name = '';
        this.form.inner_page_type = _data.jump_type;
        break;
      default:
        this.form.remark = _data.remark;
        this.outer_navigation_link = _data.remark;
        this.form.remark_name = '';
        this.form.navigation_type = _data.jump_type;
        break;
    }
    if (_data.jump_type === 1 || _data.jump_type === 2 || _data.jump_type === 5) {
      this.form.remark = _data.remark;
    }
  }

  @changeLoading(['is_loading'])
  async mounted() {
    await this.fetchSpecialZoneData();
    await this.fetchWareCategoryList();
    await this.fetchWareList();
    // 编辑
    if (this.$route.query.id) {
      this.banner_id = +this.$route.query.id;
      this.is_edit = true;
      await this.fetchBannerData(this.banner_id);
    }
  }
}
