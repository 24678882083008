
import { Component, Mixins } from 'vue-property-decorator';
import { getValidatorMap, Validate } from '@/mixin/validator';
import { queryZoneById } from '@/api/operation/special_zone';
import { changeLoading } from '@/util/decorators';
import SingleImageUpload from '@/component/single-image-upload.vue';
import SamplePopover from '@/component/sample-popover.vue';
import storage from 'baimahu/storage';

// 上传文件的路径
const UPLOAD_DIR = {
  home: '/zone/home',
  home_plus: '/zone/home_plus',
};

@Component({
  components: {
    SingleImageUpload,
    SamplePopover,
  },
})
export default class AddZoneFirst extends Mixins(Validate) {
  // 表单布局
  form_item_layout = {
    labelCol: { span: 3 },
    wrapperCol: { span: 21 },
  };
  form: any = {
    title: '',
    subtitle: '',
    home_image_url: '',
    home_image_plus_url: '',
  };
  validator_list: any = [
    {
      field: 'title',
      message: '请填写模块标题',
    },
    {
      field: 'subtitle',
      message: '请填写模块副标题',
    },
    {
      field: 'home_image_url',
      message: '请上传首页图',
    },
  ];
  validatorMap: { [field: string]: Validator } = getValidatorMap(this.validator_list);

  is_loading: boolean = false;
  is_edit: boolean = false;
  zone_id: number = 0;
  MAX_TITLE_LENGTH: number = 6;
  MAX_SUBTITLE_LENGTH: number = 12;

  preview_image: string = ''; // 预览图片的路径
  preview_visible: boolean = false; // 预览图片的模态框显示状态

  get UPLOAD_DIR(): any {
    return UPLOAD_DIR;
  }

  back() {
    this.$router.go(-1);
    storage.removeItem('zone_first');
  }

  // 通用图片上传校验
  beforeCommonUpload(file: any) {
    if (file.size / 1024 / 1024 > 2) {
      this.$message.error('上传图片大小不能超过2M');
      return false;
    }
  }

  // 显示模态窗，改变模态窗中的图片路径
  handlePreview(imageUrl: string) {
    this.preview_image = imageUrl;
    this.preview_visible = true;
  }
  // 关闭模态窗
  handlePreviewCancel() {
    this.preview_visible = false;
  }

  // 获取专区详情
  @changeLoading(['is_loading'])
  async fetchZoneData(id: number) {
    const res = await queryZoneById(id);
    this.form.title = res.title;
    this.form.subtitle = res.subtitle;
    this.form.home_image_url = res.home_image_url;
    this.form.home_image_plus_url = res.home_image_plus_url;
  }

  // 下一步
  next() {
    if (!this.validateCommit()) {
      return;
    }
    storage.setItem('zone_first', this.form);
    if (this.is_edit) {
      this.$router.push({ path: `/operation/add-zone-second?id=${this.zone_id}` });
    } else {
      this.$router.push('/operation/add-zone-second');
    }
  }

  async mounted() {
    // 编辑
    //console.log(storage.getItem('zone_first'))
    if(storage.getItem('zone_first')!=null){
      this.form=storage.getItem('zone_first')
    }
    if (this.$route.query.id) {
      this.zone_id = +this.$route.query.id;
      this.is_edit = true;
      await this.fetchZoneData(this.zone_id);
    }
  }
}
