import { render, staticRenderFns } from "./special-zone.vue?vue&type=template&id=6ef85db8"
import script from "./special-zone.vue?vue&type=script&lang=ts"
export * from "./special-zone.vue?vue&type=script&lang=ts"
import style0 from "./special-zone.vue?vue&type=style&index=0&id=6ef85db8&prod&lang=stylus&module=true"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

export default component.exports