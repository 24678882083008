
import { Component, Mixins } from 'vue-property-decorator';
import { changeLoading } from '@/util/decorators';
import { date } from '@/util/adapter';
import { Validate, getValidatorMap } from '@/mixin/validator';
import storage from 'baimahu/storage';
import moment from 'moment';
import { isCross, queryLimitTimeById } from '@/api/operation/panic_buying';
const template_time = ['08:00:00-09:59:59', '10:00:00-13:59:59', '14:00:00-15:59:59',
'16:00:00-17:59:59','18:00:00-19:59:59'];

interface Form {
  time_type: number;
  start_time: any;
  end_time: any;
  time_bucket: any;
  range_time: any;
}

function getValidatorList(time_type: number) {
  return time_type === 1
    ? [
        {
          field: 'start_time',
          message: '请选择活动开始时间',
        },
        {
          field: 'end_time',
          message: '请选择活动结束时间',
        },
      ]
    : [
        {
          field: 'time_bucket',
          message: '请选择活动时间段',
        },
      ];
}

function getFormData(res: any) {
  res = res || {};
  return {
    time_type: res.subtype || 1, // 1为自定义时间 2为模版时间
    start_time: moment(res.start_time * 1000) || '',
    end_time: moment(res.end_time * 1000) || '',
    time_bucket: '',
    range_time: 0,
  };
}

@Component({})
export default class AddPanicFirst extends Mixins(Validate) {
  form: Form = {
    time_type: 1, // 1为自定义时间 2为模版时间
    start_time: '',
    end_time: '',
    time_bucket: '',
    range_time: 0,
  };
  form_item_layout: any = {
    labelCol: { span: 3 },
    wrapperCol: { span: 21 },
  };
  validator_list: any[] = getValidatorList(this.form.time_type);
  validatorMap: { [field: string]: Validator } = getValidatorMap(this.validator_list);
  template_time_range = template_time;
  is_edit: boolean = false;
  is_loading: boolean = false;
  start_time_choose: boolean=false//开始日期是否可以编辑
  new_date: any //当前日期参数
  //获取当前时间
  timestampToTime() {
    const date = new Date();
    const Y = date.getFullYear() + '-';
    const M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
    const D = date.getDate() < 10 ? '0' + date.getDate() : date.getDate() + ' ';
    const hh = date.getHours() < 10 ? '0' + date.getHours() : date.getHours() + ':';
    const mm = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes() + ':';
    const ss = date.getSeconds() < 10 ? '0' + date.getDate() : date.getSeconds() ;
    console.log(hh)
    return Y + M + D + hh +':' + mm +':'+ ss;
    
    }

  // 获取单个限时活动的信息
  @changeLoading(['is_loading'])
  async getActivityInfo() {
    const res = await queryLimitTimeById(+this.$route.query.id);
    this.form = getFormData(res.data);
      const start_time = moment(res.data.start_time * 1000)
        .format('YYYY-MM-DD HH:mm:ss');
      const end_time = moment(res.data.end_time * 1000)
        .format('YYYY-MM-DD HH:mm:ss')
        .split(' ')[1];
      this.form.time_bucket = moment(
        moment(res.data.start_time * 1000)
          .format('YYYY-MM-DD HH:mm:ss')
          .split(' ')[0]
      );
      const range_start=moment(res.data.start_time * 1000).format('HH:mm:ss');
      const range_end=moment(res.data.end_time * 1000).format('HH:mm:ss')
      const range_time = `${range_start}`+'-'+`${range_end}`;
      console.log(this.form)
      this.form.range_time = this.template_time_range.indexOf(range_time);
      if(this.form.range_time==-1){
        this.form.range_time=0
      }
    
    //判断活动开始时间是否可以编辑
     this.new_date=this.timestampToTime()
    if(start_time>this.new_date){
      this.start_time_choose=false
    }else{
      this.start_time_choose=true
    }
    console.log(this.new_date,start_time,this.start_time_choose)
    console.log(JSON.parse(JSON.stringify(this.form)))
  }
  


  // 点击下一步按钮
  async next() {
    this.validatorMap = getValidatorMap(getValidatorList(this.form.time_type));
    if (!this.validateCommit()) {
      return;
    }
    let start_time, end_time;
    if (this.form.time_type === 1) {
      start_time = this.form.start_time.format('X');
      end_time = this.form.end_time.format('X');
    } else {
      start_time =
        Date.parse(
          `${date(this.form.time_bucket)} ${
            this.template_time_range[this.form.range_time].split('-')[0]
          }`
        ) / 1000;
      end_time =
        Date.parse(
          `${date(this.form.time_bucket)} ${
            this.template_time_range[this.form.range_time].split('-')[1]
          }`
        ) /
          1000;
    }
    const now_date = ~~(+new Date() / 1000);
    if (!this.is_edit) {
      if (start_time < now_date) {
        this.$message.error('请确认活动开始时间大于当前时间');
        return;
      } else {
        if (end_time < start_time) {
          this.$message.error('请确认活动开始时间小于活动结束时间');
          return;
        }
      }
      const res = await isCross(start_time, end_time);
      if (res.status !== 200) {
        this.$message.error('请确认当前活动时间段不在已有活动的时间段内');
        return;
      }
    }
    if (this.is_edit) {
      if (end_time < start_time) {
        this.$message.error('请确认活动开始时间小于活动结束时间');
        return;
      }
    }
    const send_data = {
      subtype: this.form.time_type,
      start_time: start_time,
      end_time: end_time,
    };
    storage.setItem('send_data', send_data);
    const url = this.$route.query.id
      ? `/operation/add-panic-second?id=${this.$route.query.id}`
      : '/operation/add-panic-second';
    this.$router.push(url);
  }

  // 切换时间类型，重新获取需要验证的表单
  changeTimeType() {
    this.validator_list = getValidatorList(this.form.time_type);
    this.validatorMap = getValidatorMap(this.validator_list);
  }
  async created() {
    this.is_edit = Boolean(this.$route.query.id);
    if (this.is_edit) {
      await this.getActivityInfo();
      this.validator_list = getValidatorList(this.form.time_type);
      this.validatorMap = getValidatorMap(this.validator_list);
    }
  }
}
