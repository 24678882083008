
import { Component, Vue } from 'vue-property-decorator';
import { changeLoading } from '@/util/decorators';
import { queryZone, updateZoneSort, updateZoneStatus } from '@/api/operation/special_zone';
import EditableCell from '@/component/editable-cell.vue';
import { useGlobalFunction } from '@/pinia/modules/globalFunction';
const columns = [
  {
    title: '排序值',
    dataIndex: 'sort',
    key: 'sort',
    width: '12%',
    scopedSlots: {
      customRender: 'sort',
    },
  },
  {
    title: '专区',
    dataIndex: 'zone',
    key: 'zone',
    scopedSlots: {
      customRender: 'zone',
    },
  },
  {
    title: '模块数',
    dataIndex: 'box_count',
    key: 'box_count',
  },
  {
    title: '状态',
    dataIndex: 'status_text',
    key: 'status',
  },
  {
    title: '最新编辑时间',
    dataIndex: 'update_time',
    key: 'update_time',
    scopedSlots: {
      customRender: 'update_time',
    },
  },
  {
    title: '操作',
    dataIndex: 'operation',
    key: 'operation',
    scopedSlots: {
      customRender: 'operation',
    },
  },
];

@Component({
  name: 'SpecialZone',
  components: {
    EditableCell,
  },
})
export default class SpecialZone extends Vue {
  setGlobalFunction=useGlobalFunction().setGlobalFunction;
  is_loading: boolean = false;
  columns = columns;
  next_page: number = 1;

  zone_table_data: any = {};

  paginationOption(data: RemotePagination<SpecialZoneData>) {
    return {
      total: data.total || 0,
      showTotal: () => `共${data.total}条，每页${data.limit}条`,
      pageSize: data.limit || 1,
      current_page: data.current || 1,
    };
  }

  handleTableChange(pagination: any) {
    this.next_page = pagination.current;
    this.fetchSpecialZoneData(this.next_page);
  }

  // 更改排序
  @changeLoading(['is_loading'])
  async handleSortChange(record: any, value: number) {
    if (!/^[0-9]+$/.test(value + '')) {
      this.$message.error('排序值须为>=0的整数值！');
      return;
    }
    /* if (value == 0) {
      this.$message.error('排序值不能为0！');
      return;
    } */
    const res: any = await updateZoneSort(record.id, value);
    if (res.status !== 200) {
      return this.$message.error(res.message);
    }
    this.$message.success('更新排序值成功');
    this.fetchSpecialZoneData(this.next_page);
  }

  // 编辑上/下架
  @changeLoading(['is_loading'])
  async handleStatusChange(record: any) {
    let status = 1;
    switch (record.status) {
      case 1:
        status = 2;
        break;
      case 2:
        status = 1;
        break;
      default:
        break;
    }
    const res: any = await updateZoneStatus(record.id, status);
    if (res.status !== 200) {
      this.$message.error(res.message);
      return;
    }
    this.fetchSpecialZoneData(this.next_page);
  }

  // 获取特色专区数据
  async fetchSpecialZoneData(current: number = 1) {
    this.zone_table_data = await queryZone(current);
  }

  @changeLoading(['is_loading'])
  async mounted() {
    await this.fetchSpecialZoneData();
    await this.setGlobalFunction({fetchSpecialZoneData:this.fetchSpecialZoneData}) //更新数据
  }
}
